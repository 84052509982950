<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Fees" subtitle="See all the fees incurred through badges and sales.">

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no fees found.">

		<template v-slot:persist>
				
			<app-content-summary :fixed="true">

				<app-content-summary-item :grow="true" label="Badge fees" :value="$org.tracking.billing.fees.badges | formatMoney('+')" />
				<app-content-summary-item :grow="true" label="Sale fees" :value="$org.tracking.billing.fees.sales | formatMoney('+')" />
				<app-content-summary-item :grow="true" label="Total fees" :value="$org.tracking.billing.fees.platform | formatMoney('=')" />

			</app-content-summary>

		</template>

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Date" />
				<app-table-column text="Convention" />
				<app-table-column text="Sale No." />
				<app-table-column text="Badge No." />
				<app-table-column text="Type" />
				<app-table-column text="Fee" />

			</app-table-head>

			<app-table-body>

				<app-table-row :no-click="true" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.date | localDate('MM/DD/YYYY h:mma')" />
					<app-table-cell-text :text="item.convention.name" />
					<app-table-cell-text :can-empty="true" :text="(item.sale) ? item.sale.reference : false" />
					<app-table-cell-text :can-empty="true" :text="(item.badge) ? item.badge.reference : false" />
					<app-table-cell-text :text="$constants.billing.charge.type[item.type]" />
					<app-table-cell-text :text="item.amount | formatMoney" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: '140px auto 120px 120px 200px 120px',
			endpoint: 'organisation/billing/fees',
			live: 'organisation/billing/fees',
			edit: 'Organisation.Billing.Fees.Edit'
		}

	}

}

</script>

<style scoped>

.summary-wrapper {
	display: flex;
}

.summary-wrapper-badge {
	margin-left: 10px;
	margin-right: 10px;
}

</style>